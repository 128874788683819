import dataFetchService from "@/services/dataFetchService"
import Restaurant from "@/types/Restaurant/Restaurant"
import MenuCategory from "@/types/MenuCategory"
import Table from '@/types/Table'

export default {
    async getRestBySlug(slug: string) {
        return await dataFetchService.getItem<Restaurant>(`/api/v1/rest/${slug}`)
    },
    async getMenuListByRestSlugAndMenuSlug(restSlug: string, menuSlug: string) {
        return await dataFetchService.getList<MenuCategory[]>(`/api/v1/rest/${restSlug}/menu/${menuSlug}`)
    },
    async getTableByRestSlugAndTableSlug(restSlug: string, tableSlug: string): Promise<Table> {
        return await dataFetchService.getItem<Table>(`/api/v1/rest/${restSlug}/table/${tableSlug}`)
    },
    async checkTableByRestSlugAndTableSlug(restSlug: string, tableSlug: string): Promise<boolean> {
        try {
            await this.getTableByRestSlugAndTableSlug(restSlug, tableSlug)
            return true
        } catch (e) {
            return false
        }
    },
}
