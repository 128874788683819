export default [
    {
        path: '/:rest',
        children: [
            {
                path: ':table_slug?',
                name: 'menu',
                component: () => import('@/views/MenuView.vue'),
            },
            {
                path: ':table_slug?/cart/:id',
                children: [
                    {
                        path: '',
                        name: 'cart',
                        component: () => import('@/views/CartView.vue')
                    },
                    {
                        path: 'pay',
                        name: 'cart_pay',
                        component: () => import('@/views/PayView.vue')
                    },
                    {
                        path: 'success',
                        name: 'cart_success',
                        component: () => import('@/views/SuccessView.vue')
                    },
                ]
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not_found',
        component: () => import('@/views/PageNotFoundView.vue')
    }
]
