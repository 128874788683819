<template>
    <div
        class="max-w-screen-md mx-auto text-base safe-h-screen"
        >
        <div
            v-if="loading.active"
            class="w-screen h-screen top-0 left-0 fixed z-20 overscroll-none flex justify-center items-center"
            :class="loading.transparent ? 'bg-gray-50/20' : 'bg-gray-50 '"
        >
            <TheLoader class="text-gray-800"/>
        </div>
        <slot/>
    </div>
</template>
<script lang="ts" setup>
import TheLoader from "@/components/TheLoader.vue"
import { provide, ref } from "vue"
import { GlobalLoaderKey } from "@/helpers/symbols"

const loading = ref({
    active: false,
    transparent: false
} as const)


provide(GlobalLoaderKey, loading.value)
</script>
