import RestaurantWhiteLabel from "@/types/Restaurant/RestaurantWhiteLabel"

export default {
    setStyle(whitelabel: RestaurantWhiteLabel) {
        const style = document.createElement('style')
        let css = ''

        if (whitelabel['base_background_color']) {
            css += `.bg-base { background-color:  ${whitelabel['base_background_color']} }`
        }
        if (whitelabel['base_text_color']) {
            css += `.text-base { color:  ${whitelabel['base_text_color']} }`
        }
        if (whitelabel['base_button_background_color']) {
            css += `.bg-btn-base { background-color:  ${whitelabel['base_button_background_color']} }`
            css += `.border-bg-btn-base { border-color:  ${whitelabel['base_button_background_color']} }`
            css += `.text-bg-btn-base { color:  ${whitelabel['base_button_background_color']} }`
        }
        if (whitelabel['base_button_text_color']) {
            css += `.text-btn-base { color:  ${whitelabel['base_button_text_color']} }`
        }

        if (whitelabel['second_background_color']) {
            css += `.bg-second { background-color:  ${whitelabel['second_background_color']} }`
        }
        if (whitelabel['second_text_color']) {
            css += `.text-second { color:  ${whitelabel['second_text_color']} }`
        }
        if (whitelabel['second_button_background_color']) {
            css += `.bg-btn-second { background-color:  ${whitelabel['second_button_background_color']} }`
        }
        if (whitelabel['second_button_text_color']) {
            css += `.text-btn-second { color:  ${whitelabel['second_button_text_color']} }`
        }

        style.innerHTML = css
        document.head.appendChild(style)
    }
}
