<template>
    <div
        class="bg-inherit min-safe-h-screen flex flex-col"
    >

        <div
            class="sticky w-full flex flex-col gap-3 top-0 z-20 mb-2 p-2 bg-white shadow-[0px_8px_33px_0px_#CFCCD438] rounded-b-3xl"
        >
            <div class="flex justify-between items-center">
                <KleekLogo/>
                <img
                    v-if="whiteLabel && whiteLabel.second_logo"
                    :src="whiteLabel.second_logo"
                    :alt="restName"
                    class="h-11 p-2"
                >
            </div>
            <div id="sticky-header" class="h-11"></div>
        </div>

        <div class="grow">
            <slot/>
        </div>

        <div
            class="sticky w-full bottom-0 overflow-x-auto no-scrollbar z-10 mt-2 px-4 bg-white shadow-[0_8px_33px_0px_rgba(207,204,212,0.22)] rounded-t-3xl"
        >
            <div id="sticky-footer"></div>
        </div>

    </div>
</template>
<script lang="ts" setup>
import KleekLogo from "@/components/KleekLogo.vue"
import { computed, onMounted } from "vue"
import { useRestaurantStore } from "@/stores/restaurant"
import { useRoute, useRouter } from "vue-router"

const restStore = useRestaurantStore()
const route = useRoute()
const router = useRouter()

const whiteLabel = computed(() => {
    return restStore.whiteLabel
})

const restName = computed(() => {
    return restStore.restaurant?.name
})


onMounted(async () => {
    await router.isReady()
    if (route.params.rest) {
        await restStore.loadRestaurant(route.params.rest as string)
    }
})
</script>

<style>
</style>
