<template>
    <div
        class="max-w-screen-md bg-inherit mx-auto text-base safe-h-screen"
    >
        <slot/>
        <BottomSheet
            :is-visible="isVisible"
            :inner="inner"
            :inner-props="innerProps"
            @resolve="resolve"
            @reject="reject"
        />
    </div>
</template>
<script lang="ts" setup>
import BottomSheet from "@/components/BottomSheet.vue"
import type { Component } from "vue"
import { computed, provide, ref, shallowRef } from "vue"
import BottomSheetPromise from "@/types/BottomSheetPromise"
import { BottomSheetKey } from "@/helpers/symbols"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const promise = ref<Nullable<Promise<any>>>(null)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resolve = ref<(value: boolean | PromiseLike<any>) => void>(() => {})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reject = ref<(reason?: any) => void>(() => {})

const isVisible = computed(() => {
    return promise.value instanceof Promise
})

const inner = shallowRef<string | Component>('div')
const innerProps = ref<Record<string, unknown>>({})

const start: BottomSheetPromise = (innerComponent: string | Component, props: Record<string, unknown>) => {
    promise.value = new Promise((innerResolve, innerReject) => {
        inner.value = innerComponent
        innerProps.value = props
        resolve.value = (...params) => {
            innerResolve(...params)
            promise.value = null
        }
        reject.value = (...params) => {
            innerReject(...params)
            promise.value = null
        }
    })
    return promise.value
}

provide(BottomSheetKey, start)
</script>
