<template>
    <div>
        <slot/>
        <transition name="fade">
            <div
                v-if="isVisible"
                class="w-screen h-screen top-0 left-0 bg-gray-800/80 fixed z-20 overscroll-none"
                @click="emit('reject')"
            ></div>
        </transition>
        <transition name="slide">
            <div
                v-if="isVisible"
                class="max-h-[100%] fixed w-full bottom-0 left-0 z-30"
            >
                <div
                    class="max-w-screen-md mx-auto min-h-full h-auto bg-white text-base shadow-[0px_8px_33px_0px_#CFCCD438] rounded-t-3xl"
                >
                    <component
                        :is="inner"
                        v-bind="innerProps"
                        @resolve="(value: boolean | PromiseLike<any>) => emit('resolve', value)"
                        @reject="(reason?: any) => emit('reject', reason)"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>
<script lang="ts" setup>
import type { Component } from "vue"
import { PropType, toRef, watch } from "vue"

const emit = defineEmits<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    'resolve': [value: boolean | PromiseLike<any>],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    'reject': [reason?: any],
}>()

const props = defineProps({
    isVisible: {
        type: Boolean,
        default: false
    },
    inner: {
        type: [String, Object] as PropType<string | Component>,
        required: true
    },
    innerProps: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        type: Object as PropType<Record<string, any>>,
        required: true
    }
})

const isVisible = toRef(props, 'isVisible')

watch(isVisible, (val) => {
    if (val) {
        document.body.classList.add('no-scroll')
    } else {
        document.body.classList.remove('no-scroll')
    }
})
</script>
