import { computed, ref } from "vue"

export default (statuses: string[]) => {
    const loading = ref<Record<string, boolean>>(
        statuses.reduce((acc, curr) => {
            acc[curr] = false
            return acc
        }, {} as Record<string, boolean>)
    )

    const isLoading = computed(() => {
        return Object.values(loading.value).some((el) => el)
    })

    async function statusDecorator<T>(promise: Promise<T>, name: string): Promise<T> {
        loading.value[name] = true
        return promise.finally(() => loading.value[name] = false)
    }

    return {
        loading,
        isLoading,
        statusDecorator
    }
}
