import { defineStore } from "pinia"
import Restaurant from "@/types/Restaurant/Restaurant"
import restService from "@/services/restService"
import router from "@/router"
import { computed, ref } from "vue"
import loaderStoreTrait from "@/stores/loaderStoreTrait"
import styleService from "@/services/styleService"

export const useRestaurantStore = defineStore('restaurantStore', () => {
    const restMap = ref<Record<string, Restaurant>>({})

    const {
        loading,
        isLoading,
        statusDecorator
    } = loaderStoreTrait(['load'])

    const restaurant = computed(() => {
        const route = router.currentRoute.value
        const slug = route.params.rest
        if (typeof slug !== "string") {
            return null
        }
        return restMap.value[slug] || null
    })

    const params = computed(() => ({
        tipsMessageShow: restaurant.value?.tips_message_show || null,
        commissionChecked: restaurant.value?.commission_checked || null,
        commissionFixed: restaurant.value?.commission_fixed || null,
        merchantCommission: restaurant.value?.merchant_commission || 0,
        tipsType: restaurant.value?.tips_type,
        tipsSteps: restaurant.value?.tips_steps || [],
        tipsDefaultStep: restaurant.value?.tips_default_step ? (restaurant.value?.tips_default_step - 1) : 0,
        tipsEmoji: restaurant.value?.tips_emoji
    } as const))

    const whiteLabel = computed(() => {
        return restaurant.value?.custom_theme ? restaurant.value?.white_label || null : null
    })

    const additionalParams = computed(() => {
        return restaurant.value?.additional_params || null
    })

    async function loadRestaurant(slug: string) {
        restMap.value[slug] = await statusDecorator(restService.getRestBySlug(slug), 'load')
        if (whiteLabel.value) {
            styleService.setStyle(whiteLabel.value)
        }
        if (restMap.value[slug].tips_steps) {
            tipsDefault.value = restMap.value[slug].tips_default_step
            restMap.value[slug].tips_steps.push(0)
        }
        return restMap.value[slug]
    }

    const tipsDefault = ref<Nullable<number>>(null)
    const tipsCustomSum = ref(0)

    return {
        restaurant,
        loading,
        isLoading,
        loadRestaurant,
        params,
        whiteLabel,
        additionalParams,
        tipsDefault,
        tipsCustomSum,
    }
})
